<template>
  <div>
    <v-dialog v-model="openModal" max-width="500">
      <v-card class="pa-5">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="mb-0 font-weight-bold">
                ¿Estas seguro de enviar a la sunat la venta ({{ (idventa).toString().padStart(7,0) }})?
              </p>
            </v-col>
            <v-col cols="12" class="text-center mt-2">
              <v-btn
                :loading="processForm"
                class="mr-2"
                color="error"
                outlined=""
                @click="openModal = false"
              >
                <v-icon left="">mdi-backspace</v-icon> Cerrar
              </v-btn>
              <v-btn :loading="processForm" color="success" @click="modificarEstadoVenta">
                <v-icon left="">mdi-check-bold</v-icon> Confirmar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openModal: false,
      processForm: false,
      idventa: ""
    };
  },
  props: {
    objModalEmitirSunat: {
      type: Object,
    },
  },
  watch: {
    objModalEmitirSunat(val) {
      this.openModal = val.openModal;
      this.idventa = val.idventa;
      
      if (this.$moment(val.created_at).isBefore('2024-03-01')) {
        this.openModal = false;
        this.$toasted.error("No se puede emitir una factura anterior a 01/03/2024.", { icon: "mdi-close", });
        return; 
      }

    },
  },

  methods: {
    modificarEstadoVenta() {
      this.processForm = true;
      this.axios({
        method: "POST",
        url: "api/panel/ventas/v2/emitirComprobanteSunat",
        data: {
          idventa: this.idventa,
        },
      })
        .then((response) => {
          let status = response.status;
          let data = response.data;
          if (status === 200) {
            this.openModal = false;

            // window.open(data.response_api.enlace_del_pdf,'_blank');

            this.$toasted.success(response.data.mensaje, {
              icon: "mdi-check-bold",
            });

            this.$parent.listarRegistros();
          }
        })
        .catch((error) => {
          const response = error.response;
          const data = response.data;
          console.log("🚀 ~ data:", data)

          if (data.mensaje) {
            this.$toasted.error(data.mensaje, { icon: "mdi-close", });
          }else{
            this.$toasted.error("Ocurrió un error al emitir el comprobante. \n Error: "+data.error, { icon: "mdi-close", });
          }

        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },
};
</script>
