<template>
  <div>
    <v-dialog v-model="openModal" max-width="900">
      <v-card >
        <v-card-title>
          <v-card-title class="justify-center"> EDITAR Y EMITIR COMPROBANTE </v-card-title>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-5">
          <v-row>
         
            <v-col cols="12" md="3">
              <v-text-field outlined dense hide-details label="Venta (*)" :value="idventa" disabled />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense hide-details label="Tipo comprobante (*)" :value="comprobante.tipo_comprobante_nombre" disabled />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense hide-details label="Serie (*)" v-model="comprobante.serie" />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense hide-details label="Correlativo (*)" v-model="comprobante.correlativo" />
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field outlined dense hide-details label="Cliente (*)" :value="cliente.nombres" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined dense hide-details label="Correo (*)" :value="cliente.correo" disabled />
            </v-col>
            <v-col cols="12" >
              <!-- <v-text-field outlined dense hide-details label="Cliente documento (*)"  :prefix="cliente.tipo_documento_nombre+' -- ' " :value="cliente.numero_documento" disabled/> -->
              <v-row>
                <v-col cols="2" >
                  <v-select 
                    :items="tiposDocumentos" 
                    item-text="name" 
                    item-value="id"
                    dense 
                    label="Cliente tipo documento (*)" 
                    outlined 
                    hide-details 
                    @change="changeTipoDocumento"
                    v-model="cliente.tipo_documento" />
                </v-col>

                <v-col cols="10" >
                  <v-text-field 
                    outlined 
                    dense 
                    hide-details 
                    label="Cliente Nro documento (*)"  
                    :minLength="tipoDocumentoSelected.minLength"
                    :maxLength="tipoDocumentoSelected.maxLength"
                    :disabled="cliente.tipo_documento == '-'"
                    v-model="cliente.numero_documento" />
                </v-col>

              </v-row>

            </v-col>
            <v-col cols="12" >
              <v-text-field outlined dense hide-details label="Dirección (*)"  :value="cliente.direccion" disabled/>
            </v-col>

            <v-col cols="12">
              <v-simple-table fixed-header >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center"> Nombre articulo </th>
                      <th class="text-center"> Precio </th>
                      <th class="text-center"> Cantidad </th>
                      <th class="text-center"> Subtotal </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr v-for="item in ventaDetalle" :key="item.idventa_detalle" >
                      <td>{{ item.nombre }}</td>
                      <td><v-text-field  outlined dense hide-details prefix="S/ " type="number" step="0.1" min="0" v-model.number="item.precio_unitario" @input="item.precio_total = item.precio_unitario * item.cantidad" /></td>
                      <td><v-text-field  outlined dense hide-details type="number" step="1" min="0" v-model.number="item.cantidad" @input="item.precio_total = item.precio_unitario * item.cantidad" disabled/></td>
                      <td class="text-no-wrap">S/ {{ number_format(item.precio_total,2) }}</td>
                    </tr>

                    <tr v-for="(item,idx) in envios" :key="idx" >
                      <td>{{ item.nombre }}</td>
                      <td><v-text-field  outlined dense hide-details prefix="S/ " type="number" step="0.1" min="0" v-model.number="item.precio" /></td>
                      <td><v-text-field  outlined dense hide-details value="1" disabled/></td>
                      <td class="text-no-wrap">S/ {{ number_format(item.precio,2) }}</td>
                    </tr>

                    <tr v-for="(item,idx) in ventaDetalleAdicional" :key="item.key" >
                      <td><v-text-field  outlined dense hide-details placeholder="Descripción (*)" type="text" v-model="item.nombre" /></td>
                      <td><v-text-field  outlined dense hide-details prefix="S/ " type="number" step="0.1" min="0" v-model.number="item.precio_unitario" @input="item.precio_total = item.precio_unitario * item.cantidad" /></td>
                      <td><v-text-field  outlined dense hide-details type="number" step="1" min="0" v-model.number="item.cantidad" @input="item.precio_total = item.precio_unitario * item.cantidad" disabled/></td>
                      <td class="text-no-wrap">S/ {{ number_format(item.precio_total,2) }}</td>
                      <td>
                        <v-btn 
                          color="error"
                          fab
                          x-small
                          dark
                          @click="removeItemDetalle(idx)" >
                          <v-icon >mdi-minus</v-icon>
                        </v-btn>
                      </td>
                    </tr>

                    <tr align="center" justify="center">
                      <td colspan="4" >
                        <v-btn color="primary" @click="addItemDetalle"  >
                          <v-icon left="">mdi-plus</v-icon> Agregar
                        </v-btn>
                      </td>
                    </tr>

                    

                  </tbody>
                </template>
              </v-simple-table>

              
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea outlined dense hide-details label="Observaciones" v-model="venta.observaciones" />
            </v-col>
            <v-col cols="12" md="6" >
              <v-simple-table fixed-header >
                <template v-slot:default>
                  <tbody>
                      <tr>
                        <td colspan="3">DESCUENTO</td>
                        <td><v-text-field  outlined dense hide-details prefix="S/ " type="number" step="0.1" min="0" v-model.number="venta.descuento" /></td>
                      </tr>
                      <tr>
                        <td colspan="3">GRAVADA</td>
                        <td><v-text-field  outlined dense hide-details prefix="S/ " :value="montos.gravada" disabled/></td>
                      </tr>
                      <tr>
                        <td colspan="3">IGV</td>
                        <td><v-text-field  outlined dense hide-details prefix="S/ " :value="montos.igv" disabled /></td>
                      </tr>
                      <tr>
                        <td colspan="3">TOTAL</td>
                        <td><v-text-field  outlined dense hide-details prefix="S/ " :value="total" disabled /></td>
                      </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>



            <v-col cols="12" class="mt-2" v-if="errors.length > 0">
              <v-alert type="error">
                <ul>
                  <li v-for="(msgError,idx) in errors" :key="idx" v-text="msgError"></li>
                </ul>
              </v-alert>

            </v-col>

            <v-col cols="12" class="text-center mt-2">
              <v-btn :loading="processForm" class="mr-2" color="error" outlined="" @click="openModal = false" >
                <v-icon left="">mdi-backspace</v-icon> Cerrar
              </v-btn>
              <v-btn :loading="processForm" color="success" @click="emitirComprobante">
                <v-icon left="">mdi-check-bold</v-icon> Confirmar
              </v-btn>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openModal: false,
      processForm: false,
      errors: [],
      tiposDocumentos: [],
      tipoDocumentoSelected: {
        id: '-',
        name: 'VARIOS',
        minLength: 8,
        maxLength: 8,
      },
      idventa: "",
      comprobante: {
        tipo_comprobante: '-',
        tipo_comprobante_nombre: 'Varios',
        serie: '',
        correlativo: 1,
        nro_comprobante: '',
      },
      cliente: {
        tipo_documento: '',
        numero_documento: '',
        nombres: '',
        direccion: '',
        correo: '',
      },
      venta: {},
      ventaDetalle: [],
      ventaDetalleAdicional: [],
      envios: [],
      montos: {
        gravada: '0.00',
        igv: '0.00',
        total: '0.00',
      }
    };
  },
  props: {
    objModalEditarEmitirSunat: {
      type: Object,
    },
  },
  watch: {
    objModalEditarEmitirSunat(val) {
      this.openModal = val.openModal;
      this.idventa = val.idventa;

      if (this.$moment(val.created_at).isBefore('2024-03-01')) {
        this.openModal = false;
        this.$toasted.error("No se puede emitir una factura anterior a 01/03/2024.", { icon: "mdi-close", });
        return;
      }

      if (val.openModal) {
        this.comprobante = {};
        this.cliente = {};
        this.venta = {};
        this.ventaDetalle = [];
        this.envios = [];
        this.montos = {
          gravada: '0.00',
          igv: '0.00',
          total: '0.00',
        };


        this.getData();
      }
    },
    "comprobante.serie"(val) {
      this.generateNroComprobante();
    },
    "comprobante.correlativo"(val) {
      this.generateNroComprobante();
    }

  },
  computed: {
    total(){
      const val1 = this.ventaDetalle.reduce( (acc,ele) => parseFloat(acc)+parseFloat(ele.precio_total), 0);
      const val3 = this.ventaDetalleAdicional.reduce( (acc,ele) => parseFloat(acc)+parseFloat(ele.precio_total), 0);
      const val2 = this.envios.reduce( (acc,ele) => parseFloat(acc)+parseFloat(ele.precio), 0);

      const montoVenta = val1 + val2 + val3;
      const montoDescuento = parseFloat(this.venta.descuento || "0.00");

      const valorVenta = montoVenta / 1.18;
      const valorDescuento = montoDescuento / 1.18;

      const gravada = valorVenta - valorDescuento;
      const igv = gravada * 0.18;
      const total = gravada + igv;
      
      this.montos.gravada = this.number_format(gravada,2);
      this.montos.igv = this.number_format(igv,2);
      this.montos.total = this.number_format(total,2);
      this.venta.total = this.number_format(total,2);

      return this.montos.total;
    },
    
  },

  methods: {
    number_format(number, decimals = 2, dec_point= ".", thousands_sep = "") {
        // Strip all characters but numerical ones.
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    },
    getData() {
      this.processForm = true;
      this.axios({
        method: "POST",
        url: "api/panel/ventas/v2/editComprobanteSunat",
        data: {
          idventa: this.idventa,
        },
      })
        .then((response) => {
          const data = response.data;

          this.tiposDocumentos = data.tipos_documentos;
          this.comprobante = data.comprobante;
          this.cliente = data.cliente;
          this.venta = data.venta;
          this.ventaDetalle = data.venta_detalle;
          this.envios = data.envios;

        })
        .catch((error) => {
          const response = error.response;
          const data = response.data;
          console.log("🚀 ~ data:", data)

          if (data.mensaje) {
            this.$toasted.error(data.mensaje, { icon: "mdi-close", });
          }else{
            this.$toasted.error("Ocurrio un error al modificar este registro", { icon: "mdi-close", });
          }

        })
        .finally(() => {
          this.processForm = false;
        });
    },
    validacion() {
      const {cliente,ventaDetalleAdicional} = this;
      const errors = [];

      if (cliente.numero_documento != '-' && !cliente.numero_documento) {
        errors.push("No se puede emitir un comprobante sin número de documento.");
      }

      if (cliente.numero_documento == '-' && this.venta.total >= 500) {
        errors.push("No se puede emitir una BOLETA a 'Varios' si el monto es mayor o igual a S/ 500, debe seleccionar otro tipo de documento.");
      }

      const itemInvalidos = ventaDetalleAdicional.filter(ele => !ele.nombre || !ele.precio_unitario);
      if(itemInvalidos.length > 0) {
        errors.push('No puede haber items sin descripción y/o con precio S/ 0.00.');
      }

      return errors;
    },
    emitirComprobante() {
      this.processForm = true;

      const errors = this.validacion();
      if ( errors.length > 0 ) {
        this.processForm = false;
        this.errors = errors;
        setTimeout(() => {
          this.errors = [];
        }, 1000* 5);
        return;
      }
      

      const ventaDetalle = this.ventaDetalle.concat(this.ventaDetalleAdicional);
      
      this.axios({
        method: "POST",
        url: "api/panel/ventas/v2/emitirComprobanteEditadoSunat",
        data: {
          idventa: this.idventa,
          comprobante: this.comprobante,
          cliente: this.cliente,
          venta: this.venta,
          venta_detalle: ventaDetalle,
          envios: this.envios,
        },
      })
        .then((response) => {
          const status = response.status;
          const data = response.data;
                    
          this.openModal = false;

          // window.open(data.response_api.enlace_del_pdf,'_blank');
          this.$toasted.success(data.mensaje, { icon: "mdi-check-bold", });
          this.$parent.listarRegistros();


        })
        .catch((error) => {
          const response = error.response;
          const data = response.data;
          console.log("🚀 ~ data:", data)

          if (data.mensaje) {
            this.$toasted.error(data.mensaje, { icon: "mdi-close", });
          }else{
            this.$toasted.error("Ocurrió un error al emitir el comprobante. \n Error: "+data.error, { icon: "mdi-close", });
          }

        })
        .finally(() => {
          this.processForm = false;
        });
    },
    generateNroComprobante() {
      const {serie, correlativo} = this.comprobante;
      // console.log("🚀 ~ {serie, correlativo}:", {serie, correlativo})

      const correlativoAdded = (correlativo||1).toString().padStart(6,0);

      this.comprobante.nro_comprobante = serie+"-"+correlativoAdded;
    },
    changeTipoDocumento() {
      const tipoDocumentoID = this.cliente.tipo_documento;
      if(tipoDocumentoID == '-' ) {
        this.cliente.numero_documento = '';
      }

      this.tipoDocumentoSelected = this.tiposDocumentos.find(ele => ele.id == tipoDocumentoID);
      this.cliente.numero_documento = (this.cliente.numero_documento).slice(0,this.tipoDocumentoSelected.minLength);

    },

    addItemDetalle() {
      const uniqueKey = Date.now().toString();

      this.ventaDetalleAdicional.push({
        key: uniqueKey,
        codigo: null,
        nombre: "",
        precio_unitario: "1.00",
        cantidad:1,
        precio_total: "1.00"
      });
    },
    removeItemDetalle(itemIdx) {
      this.ventaDetalleAdicional = this.ventaDetalleAdicional.filter( (ele,idx) => idx != itemIdx);
    },
  },
};
</script>
